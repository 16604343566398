import styled from "styled-components"
import { Button } from "../UI/Button"
import { StyledButton, StyledButtonText } from "../UI/Button/styled"

export const Backdrop = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
`

export const CtaButton = styled.button`
  display: flex;
  gap: 8px;
  background: transparent;
  border: 0;
  color: ${p => p.theme.colors.white[1]};
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; 
  padding: 0;
`

export const CtaDescription = styled.p`
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  color: rgba(255, 255, 255, 0.60);
`

export const CtaButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
`

export const DropdownCard = styled.div`
  background: ${p => p.theme.colors.azure[6]};
  border-radius: 0.5rem;
  box-shadow: ${p => p.theme.shadows.menuOpen};
  height: 100%;
  overflow: hidden;
  overscroll-behavior: contain;
  position: relative;
  transform-origin: top right;
  transform: scale(0.98);
  transition: transform 0.2s ease-in-out;
  width: 100%;
  z-index: 11;
`

export const DropdownWrapper = styled.div<{
  active?: boolean
}>`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  opacity: ${({ active }) => (active ? 1 : 0)};
  overflow: hidden;
  overscroll-behavior: contain;
  padding: 0.4rem;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  width: 100%;
  z-index: 10000;

  &.DropdownWrapper--isOpen ${DropdownCard} {
    transform: scale(1);
  }
`

export const WizzardTitle = styled.h3`
  color: ${p => p.theme.colors.black[1]};
  font-family: Source Sans Pro;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; 

  @media (max-width: 1200px) {
    width: 100%;
  }
`

export const WizzardDescription = styled.p`
  font-family: Source Sans Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const WizzardLayout = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  justify-content: center;
  padding: 2rem;

  @media (max-width: 1200px) {
    padding: 1rem;
    justify-content: flex-start;
    overflow-y: scroll;
}
  
`

export const WizzardContent = styled.div.attrs(props => ({})) <{ columns: number }>`
  display: grid;
  width: 100%;
  min-width: 1040px;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  padding: 48px 64px;
  align-items: center;
  gap: 12px;
  border-radius: 20px;
  background: #FFFFFF;

  @media (max-width: 1200px) {
    min-width: 100%;
    padding: 12px 16px;
    grid-template-columns: 1fr;
  }
`

export const WizzardContentRecommendation = styled.div.attrs(props => ({})) <{ columns: number }>`
  display: grid;
  width: 100%;
  min-width: 1040px;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  padding: 20px;
  align-items: center;
  gap: 12px;
  border-radius: 20px;
  background: #FFFFFF;

  @media (max-width: 1200px) {
    padding: 12px 16px;
    min-width: 100%;
    grid-template-columns: 1fr;
  }
`

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`

export const Divider = styled.hr`
  background: ${p => p.theme.colors.black[6]};
  border: 0;
  height: 20px;
  margin: 0 auto;
  width: 2px;

  @media (max-width: 1200px) {
    display: none;
  }
`

export const WizzardStepperTitle = styled.div`
  color: #0D0D0D;
  font-family: Source Sans Pro;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`

export const WizzardContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: flex-start;
    margin-top: 72px;
`

export const WizzardButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    `

export const RecommendButton = styled(Button)`
    ${StyledButton}
    display: flex;
    padding: 14px 16px 14px 20px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    border: 2px solid #F8F7F7;
    background: #F8F7F7;
    border-radius: 10px;
    align-self: flex-end;
`

export const RecommendationCard = styled.div`
  display: flex;
  height: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 14px;

  &:hover {
    background: #F8F7F7;
    transition: all 0.3s ease-in-out;
  }
`

export const RecommendationUsage = styled.span`
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  color: #676765;
`

export const RecommendationTitle = styled.span`
  color: #000;
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`

export const RecommendationList = styled.ul`
  display: flex;
  padding: 0;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
  list-style: none;
`

export const RecommendationListItem = styled.li`
  display: flex;
  color: #000;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`