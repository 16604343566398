export const ALL_STORYBLOK_LANGUAGES = ['default', 'de', 'fr', 'it', 'nl']
export default ALL_STORYBLOK_LANGUAGES

export enum LANGUAGE {
  EN = 'en',
  DE = 'de',
  FR = 'fr',
  IT = 'it',
  NL = 'nl',
}

export const defaultLanguage = LANGUAGE.EN

export const INTERNAL_LANGUAGES: LANGUAGE[] = (() =>
  ALL_STORYBLOK_LANGUAGES.map(lang => {
    const language = lang === 'default' ? defaultLanguage.toUpperCase() : lang.toUpperCase()
    return LANGUAGE[language]
  }))()

export const langLabelMap = {
  [LANGUAGE.EN]: 'English',
  [LANGUAGE.DE]: 'Deutsch',
  [LANGUAGE.FR]: 'Français',
  [LANGUAGE.IT]: 'Italiano',
  [LANGUAGE.NL]: 'Nederlands',
}
